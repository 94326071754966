import React, { useEffect, useState } from 'react';
import '../Style/CreateContracts.css';
import Header from './Header';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorComponent from 'react-froala-wysiwyg';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import Toastify from 'toastify-js';
import "toastify-js/src/toastify.css";
import axios from '../Service/axios';

function CreateContracts() {

  const [isActive, setActive] = useState(0)
  const activeCon = (id) => {
    setActive(id)
  }


  const initialContent2 = `
        <h2 style="text-align: center;">
            Д О Г О В О Р    №01/019  Ю
        </h2>
        <p style="text-align: center;">   на оценку объекта оценки</p>
        <div style="display:flex;  align-items: center;  justify-content: space-between;">
          <p>  г.Гулистан     </p>
          <p> « ____» _____г.</p>
        </div>
         <h3 style="text-align: center;">
            1.   ДОГОВАРИВАЮЩИЕСЯ  СТОРОНЫ 
        </h3>
        <p>
      ООО «SMART CONSULT» именуемый в дальнейшем Исполнитель, в лице директора Назарова А.А. действующего на основании Устава и Лицензии (серии BL 001 реестровый RR-0065, выданной Госкомконкуренцией РУз от 28.11.2016г. страховой полис №Р7/3-7/0008 от 26.01.2024 года выданный АО «ALSKOM» СК) с одной стороны и ООО «________________» именуемый в дальнейшем Заказчик, в лице Каримов Ш.З. действующего на основании Устава с другой стороны заключили настоящий договор о нижеследующем:

        </p>
        <h3 style="text-align: center;">
            2.    ПРЕДМЕТ ДОГОВОРА
        </h3>
        <p>
           2.1.По возмездному договору на оценку объекта оценки, Исполнитель обязуется по поручению Заказчика, осуществить консультирование Заказчика по определению стоимости (оценки) имущества (объекта оценки) указанных в п.2.2. настоящего договора, а Заказчик обязуется оплатить оценку или производственные работы (услуги).
          2.2.Наименование объекта оценки: Оценка стоимости ущерба нанесённого оборудованию
          2.3.Расположенного по адресу:  Сырдарьинская обл., г.Янгиер
          2.4.Цель оценки: для консультирования о стоимости ущерба
          2.5.Вид определяемой стоимости: рыночная стоимость
          2.6.В случае необходимости дополнительных проработок по комплексу оценочных работ - предмету Договора, внесения изменений и уточнения по инициативе Заказчика или иным объективным причинам, включая изменения и установления дополнительных регламентирующих условий, обуславливаемых нормативными актами, требованиями органов государственного надзора или иных компетентных юридических и физических лиц, составляется Дополнительное соглашение к данному Договору или новый Договор, учитывающие изменение трудоемкости.

        </p>
         <h3 style="text-align: center;">
          3.  ЦЕНА ПРЕДМЕТА ДОГОВОРА И ПОРЯДОК РАСЧЕТОВ
        </h3>
        <p>
          3.1. Подписывая настоящий договор, Исполнитель и Заказчик удостоверяют, что в результате переговоров определен и согласован размер денежного вознаграждения за проведение оценки или производимые работы (услуги), указанные п.2.1. и реализуемые по заключаемому между Сторонами настоящему договору.
          3.2. Заказчик обязуется оплатить за проведение оценки объекта оценки денежное вознаграждение в размере: (без учета НДС) 1 150 000 (Один миллион сто пятьдесят тысяч) сум 00 тийин.
          3.3.Размер денежного вознаграждения за проведение оценки объекта оценки уточняется в случае изменения законодательной минимальной заработной платы по соглашению сторон. Индексации подлежит неоплаченная часть стоимости работ при предоплате на момент введения новой минимальной заработной платы, а индекс удорожания исчисляется, как отношение новой минимальной заработной платы к минимальной заработной плате в момент заключения договора. 
          3.4.Заказчик – юридическое лицо производит 100% предоплаты в течении 3-х банковских дней со дня подписания договора.
          В случае не оплаты в течение указанного срока, договор считается не состоявшимся и Исполнитель вправе отказаться от исполнения обязательств по настоящему договору.
          3.5.Исполнитель сдает Заказчику один экземпляр письменного отчета по оценке объекта оценки и два экземпляра подписанного акта приема-сдачи выполненных работ. 
          3.6.Заказчик обязуется в течение трех календарных дней подписать и вернуть один экземпляр акта приемки-сдачи (для юридического лица) выполненных работ или вернуть Исполнителю отчет по оценке с письменным мотивированным отказом в приеме работ.

        </p>
         <h3 style="text-align: center;">
         4.  СРОКИ ВЫПОЛНЕНИЯ РАБОТ
        </h3>
           <p>
        4.1. Датой начала выполнения оценки или работ по настоящему договору считается дата поступления на расчетный счет Исполнителя предоплаты (задатка), в соответствии с п.3.4. настоящего договора, датой окончания работ считается дата подписания акта приемки-сдачи выполненных работ. Время, затрачиваемое на оценку объекта оценки, Исполнитель определяет самостоятельно и имеет право выполнить досрочно.

        </p>
           <h3 style="text-align: center;">
        5.  ПРАВА И ОБЯЗАННОСТИ СТОРОН
        </h3>
        <p>
         5.1. Заказчик обязан:
        5.1.1. Оплатить стоимость оценки объекта оценки указанной в п.3.2. настоящего договора, за услуги Исполнителя и принять у него работу в соответствии с пунктами 3.5. и 3.6.
        5.1.2. Обеспечить предоставление информации и необходимых сведений для проведения оценки, обеспечить соответствующие условия для проведения оценки или работ (услуг)
        5.2. Заказчик имеет право:
        5.2.1. Отказаться от поручаемой оценки объекта оценки по настоящему договору по своей инициативе только в тех случаях, когда Исполнитель не приступил к исполнению настоящего договора в согласованные сроки. 
        В случае начала выполнения работ (услуг) по оценки объекта оценки Исполнителем, работа завершается на основании двухстороннего акта на выполненную часть работы
        5.3. Исполнитель обязан: 
        5.3.1. Выполнять оценку объекта оценки в соответствии со стандартами и нормативно-правовыми актами, регламентирующими оценочную деятельность в Республики Узбекистан.
        5.3.2. Сообщить Заказчику о невозможности своего участия в проведении оценки объекта оценки вследствие возникших обстоятельств, препятствующих проведению объективной оценки.
        5.4. Исполнитель имеет право:
        5.4.1.Привлекать по своему усмотрению, за свой счет, самостоятельные экспертные группы для выполнения оценки объекта оценки по настоящему договору.
        5.4.2.Требовать от заказчика: обеспечения доступа к документации; получать разъяснения и дополнительные сведения, необходимые для осуществления оценки объекта оценки, обеспечение соответствующих условий для проведения  оценки объекта оценки.
        5.4.3. Выбирать самостоятельно методы оценки.
        5.4.4.Отказаться от проведения оценки объекта оценки в случаях: если Заказчик нарушил условия договора, не обеспечил предоставление необходимой информации либо не обеспечил, соответствующие условия для работы.

        </p>
          <h3 style="text-align: center;">
             6.  ОТВЕТСТВЕННОСТЬ СТОРОН
        </h3>
        <p>
        6.1. За нарушение сроков выполнения работ Исполнитель уплачивает Заказчику пени в размере 0,5% от стоимости работ за каждый день просрочки, но не более 50% от общей суммы настоящего договора.
6.2. При не своевременной оплате, Заказчик уплачивает Исполнителю пени в размере 0,4% от суммы просроченного платежа за каждый день просрочки, но не более 50% от общей суммы настоящего договора.
6.3. Уплата пени не освобождает сторону, нарушившую договорные обязательства от их дальнейшего исполнения и возмещения убытков.
6.4.Исполнитель не несет ответственность за предоставленную Заказчиком информацию, которая необходима для выполнения  оценки или работ (услуг).
6.5.Исполнитель не несет ответственность за не выполнение оценки или работ (услуг) по срокам, в случаи не предоставления Заказчиком исходных данных.
6.6.Вся информация, полученная в ходе выполнения настоящего договора, считается строго конфиденциальной и не подлежит разглашению или передаче третьем лицам, за исключением случаев предусмотренных действующим законодательством.

        </p>
            <h3 style="text-align: center;">
              7. ФОРС-МАЖОР
        </h3>
        <p>
       7.1.Стороны не несут ответственность за не выполнение или не полное выполнение ими своих обязательств, в случае наступления обстоятельств непреодолимой силы, вызванных прямо или косвенно землетрясением, наводнением, пожаром другими стихийными бедствиями, гражданских волнений, вмешательство правительства, оказывающих влияние на выполнение обязательств сторонами по настоящему договору, сроки выполнения этих обязательств соразмерно отодвигаются  на время действия этих обстоятельств.

        </p>
           <h3 style="text-align: center;">
              8.   ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ
        </h3>
        <p>
        8.1.В случае возникновения споров между Сторонами по вопросам исполнения настоящего договора, Стороны примут все меры к их разрешению путем переговоров. Споры и разногласия, по которым Стороны не достигли договоренности, подлежат рассмотрению в установленном законодательном порядке.
8.2.Настоящий договор может быть изменен или прекращен до полного выполнения Сторонами принятых обязательств, только по взаимному соглашению Сторон. Все изменения или дополнения оформляются дополнительными соглашениями в письменной форме к настоящему договору, подписываются Сторонами и составляют его неотъемлемую часть. 

        </p>
             <h3 style="text-align: center;">
              9.    Заключительные положения.
        </h3>
        <p>
       9.1.Отношения возникшие при заключении, исполнении, изменении, расторжении настоящего договора и неоговоренные в нем, регулируются Гражданским  кодексом Р.Уз., Законом Р.Уз."О договорно-правовой базе деятельности хозяйствующих субъектов" и Законом Р.Уз."Об оценочной деятельности".
9.2.Настоящий договор составлен в двух экземплярах. Оба экземпляра идентичны и имеют одинаковую силу. Один экземпляр находится у Исполнителя, а другой - у Заказчика. 

        </p>
             <h3 style="text-align: center;">
              10.   Адреса и банковские реквизиты сторон.
        </h3>
        <div  ">
          <div style="display:flex;   flex-direction: column; border: 2px solid black; padding: 5px ;" >
              <h4 style="text-align: center;">
                  Исполнитель:
              </h4>
              <h4 style="text-align: center;">
                  ООО «SMART CONSULT»
              </h4>
              <p>
                Адрес:Сырдарьинская область, г.Гулистан, ул.Хондамир, дом№б/н. 
              </p>
              <p>
               Телефон: 91-775-07-00, 94-919-77-00
              </p>
              <p>
               Р/с: 20208000904316956001
              </p>
              <p>
               в АКИБ «Ипотека банк», г.Гулистан
              МФО: 00364; ИНН: 204901642; ОКОНХ:82100.  
              </p>
              <p>
              М.П.                          ___________ 
              </p>
          </div>
          <div style="display:flex;   flex-direction: column; border: 2px solid black; padding: 5px ;" >
              <h4 style="text-align: center;">
                 Заказчик
              </h4>
              <h4 style="text-align: center;">
                  ООО «______________»
              </h4>
              <p>
               Адрес: Сырдарьинская область, г.Янгиер

              </p>
              <p>
               тел. (____) _____________________

              </p>
              <p>
              Паспорт ____________________________________

              </p>
              <p>
              банк ___________________________

              </p>
              <p>
              МФО: _______  ИНН: _________ ОКЭД:_______

              </p>
              <p>
             М.П.    ______________ Каримов Ш.З.
              </p>
          </div>
        </div>
    `;
  const initialContent = `
        <h2 style="text-align: center;">
            Д О Г О В О Р    № __ / ___ Ф
        </h2>
        <p style="text-align: center;">на оценку объекта оценки</p>
        <div style="display:flex;  align-items: center;  justify-content: space-between;">
          <p>  г.Гулистан     </p>
          <p>   	« ____» _____г.</p>
        </div>
         <h3 style="text-align: center;">
            1.   ДОГОВАРИВАЮЩИЕСЯ  СТОРОНЫ 
        </h3>
        <p>
        ООО «SMART CONSULT» именуемый в дальнейшем Исполнитель, в лице директора Назарова А.А. действующего на основании Устава и Лицензии (серии BL 001 реестровый RR-0065, выданной Госкомконкуренцией РУз от 28.11.2016г. страховой полис №Р7/3-7/0008 от 26.01.2024 года выданный АО «ALSKOM» СК) с одной стороны и ________ именуемый в дальнейшем Заказчик, заключили настоящий договор о нижеследующем:
        </p>
        <h3 style="text-align: center;">
            2.    ПРЕДМЕТ ДОГОВОРА
        </h3>
        <p>
          2.1.По возмездному договору на оценку объекта оценки, Исполнитель обязуется по поручению Заказчика, осуществить консультирование Заказчика по определению стоимости (оценки) имущества (объекта оценки) указанных в п.2.2. настоящего договора, а Заказчик обязуется оплатить оценку или производственные работы (услуги).
          2.2.Наименование объекта оценки: <spam></span>
          2.3.Расположенного по адресу: __________________________________________________________
          2.4.Цель оценки:      
          2.5.Вид определяемой стоимости: рыночная
          2.6.В случае необходимости дополнительных проработок по комплексу оценочных работ - предмету Договора, внесения изменений и уточнения по инициативе Заказчика или иным объективным причинам, включая изменения и установления дополнительных регламентирующих условий, обуславливаемых нормативными актами, требованиями органов государственного надзора или иных компетентных юридических и физических лиц, составляется Дополнительное соглашение к данному Договору или новый Договор, учитывающие изменение трудоемкости.
        </p>
         <h3 style="text-align: center;">
          3.  ЦЕНА ПРЕДМЕТА ДОГОВОРА И ПОРЯДОК РАСЧЕТОВ
        </h3>
        <p>
          3.1. Подписывая настоящий договор, Исполнитель и Заказчик удостоверяют, что в результате переговоров определен и согласован размер денежного вознаграждения за проведение оценки или производимые работы (услуги), указанные п.2.1. и реализуемые по заключаемому между Сторонами настоящему договору.
          3.2. Заказчик обязуется оплатить за проведение оценки объекта оценки денежное вознаграждение в размере: (без учета НДС)  _______ (____________________________________) сум 00 тийин.
                                  /сумма прописью/          
          3.3.Размер денежного вознаграждения за проведение оценки объекта оценки уточняется в случае изменения законодательной минимальной заработной платы по соглашению сторон. Индексации подлежит неоплаченная часть стоимости  работ при предоплате на момент введения новой минимальной заработной платы, а индекс удорожания исчисляется, как отношение новой минимальной заработной платы к  минимальной заработной плате в момент заключения договора. 
          3.4.Заказчик – физическое лицо производит 100% предоплаты в течении 3-х банковских дней со дня подписания договора.
          В случае не оплаты в течение указанного срока, договор считается не состоявшимся и Исполнитель вправе отказаться от исполнения обязательств по настоящему договору.
          3.5.Исполнитель сдает Заказчику один экземпляр письменного отчета по оценке объекта оценки и два экземпляра подписанного акта приема-сдачи выполненных работ. 
          3.6.Заказчик обязуется в течение трех календарных дней подписать и вернуть один экземпляр акта приемки-сдачи (для юридического лица) выполненных работ или вернуть Исполнителю отчет по оценке с письменным мотивированным отказом в приеме работ.
        </p>
         <h3 style="text-align: center;">
         4.  СРОКИ ВЫПОЛНЕНИЯ РАБОТ
        </h3>
           <p>
         4.1. Датой начала выполнения оценки или работ по настоящему договору считается дата поступления на расчетный счет Исполнителя предоплаты (задатка), в соответствии с п.3.4. настоящего договора, датой окончания работ считается дата подписания акта приемки-сдачи выполненных работ. Время, затрачиваемое на оценку объекта оценки, Исполнитель определяет самостоятельно и имеет право выполнить досрочно.
        </p>
           <h3 style="text-align: center;">
        5.  ПРАВА И ОБЯЗАННОСТИ СТОРОН
        </h3>
        <p>
          5.1. Заказчик обязан:
          5.1.1. Оплатить стоимость оценки объекта оценки указанной в п.3.2. настоящего договора, за услуги Исполнителя и принять у него работу в соответствии с пунктами 3.5. и 3.6.
          5.1.2. Обеспечить предоставление информации и необходимых сведений для проведения оценки, обеспечить соответствующие условия для проведения оценки или работ (услуг)
          5.2. Заказчик имеет право:
          5.2.1. Отказаться от поручаемой оценки объекта оценки по настоящему договору по своей инициативе только в тех случаях, когда Исполнитель не приступил к исполнению настоящего договора в согласованные сроки. 

          В случае начала выполнения работ (услуг) по оценки объекта оценки Исполнителем, работа завершается на основании двухстороннего акта на выполненную часть работы
          5.3. Исполнитель обязан: 
          5.3.1. Выполнять оценку объекта оценки в соответствии со стандартами и нормативно-правовыми актами, регламентирующими оценочную деятельность в Республики Узбекистан.
          5.3.2. Сообщить Заказчику о невозможности своего участия в проведении оценки объекта оценки вследствие возникших обстоятельств, препятствующих проведению объективной оценки.
          5.4. Исполнитель имеет право:
          5.4.1.Привлекать по своему усмотрению, за свой счет, самостоятельные экспертные группы для выполнения оценки объекта оценки по настоящему договору.
          5.4.2.Требовать от заказчика: обеспечения доступа к документации; получать разъяснения и дополнительные сведения, необходимые для осуществления оценки объекта оценки, обеспечение соответствующих условий для проведения  оценки объекта оценки.
          5.4.3. Выбирать самостоятельно методы x оценки.
          5.4.4.Отказаться от проведения оценки объекта оценки в случаях: если Заказчик нарушил условия договора, не обеспечил предоставление необходимой информации либо не обеспечил, соответствующие условия для работы.
        </p>
          <h3 style="text-align: center;">
             6.  ОТВЕТСТВЕННОСТЬ СТОРОН
        </h3>
        <p>
        6.1. За нарушение сроков выполнения работ Исполнитель уплачивает Заказчику пени в размере 0,5% от стоимости работ за каждый день просрочки, но не более 50% от общей суммы настоящего договора.
        6.2. При не своевременной оплате, Заказчик уплачивает Исполнителю пени в размере 0,5% от суммы просроченного платежа за каждый день просрочки, но не более 50% от общей суммы настоящего договора.
        6.3. Уплата пени не освобождает сторону, нарушившую договорные обязательства от их дальнейшего исполнения и возмещения убытков.
        6.4.Исполнитель не несет ответственность за предоставленную Заказчиком информацию, которая необходима для выполнения  оценки или работ (услуг).
        6.5.Исполнитель не несет ответственность за не выполнение оценки или работ (услуг) по срокам, в случаи не предоставления Заказчиком исходных данных.
        6.6.Вся информация, полученная в ходе выполнения настоящего договора, считается строго конфиденциальной и не подлежит разглашению или передаче третьем лицам, за исключением случаев предусмотренных действующим законодательством.
        </p>
            <h3 style="text-align: center;">
              7. ФОРС-МАЖОР
        </h3>
        <p>
        7.1.Стороны не несут ответственность за не выполнение или не полное выполнение ими своих обязательств, в случае наступления обстоятельств непреодолимой силы, вызванных прямо или косвенно землетрясением, наводнением, пожаром другими стихийными бедствиями, гражданских волнений, вмешательство правительства, оказывающих влияние на выполнение обязательств сторонами по настоящему договору, сроки выполнения этих обязательств соразмерно отодвигаются  на время действия этих обстоятельств.
        </p>
           <h3 style="text-align: center;">
              8.   ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ
        </h3>
        <p>
          8.1.В случае возникновения споров между Сторонами по вопросам исполнения настоящего договора, Стороны примут все меры к их разрешению путем переговоров. Споры и разногласия, по которым Стороны не достигли договоренности, подлежат рассмотрению в  установленном законодательном порядке.
          8.2.Настоящий договор может быть изменен или прекращен до полного выполнения Сторонами принятых обязательств, только по взаимному соглашению Сторон. Все изменения или дополнения оформляются дополнительными соглашениями в письменной форме к настоящему договору, подписываются Сторонами и составляют его неотъемлемую часть. 
        </p>
             <h3 style="text-align: center;">
              9.    Заключительные положения.
        </h3>
        <p>
        9.1.Отношения возникшие при заключении, исполнении, изменении, расторжении настоящего договора и неоговоренные в нем, регулируются Гражданским  кодексом Р.Уз., Законом Р.Уз."О договорно-правовой базе деятельности хозяйствующих субъектов" и Законом Р.Уз."Об оценочной деятельности".
        9.2.Настоящий договор составлен в двух экземплярах. Оба экземпляра идентичны и имеют одинаковую силу. Один экземпляр находится у Исполнителя, а другой - у Заказчика.
        </p>
             <h3 style="text-align: center;">
              10.   Адреса и банковские реквизиты сторон.
        </h3>
        <div >
          <div style="display:flex;   flex-direction: column; border: 2px solid black; padding: 5px ;" >
              <h4 style="text-align: center;">
                  Исполнитель:
              </h4>
              <h4 style="text-align: center;">
                  ООО «SMART CONSULT»
              </h4>
              <p>
                Адрес:Сырдарьинская область, г.Гулистан, ул.Хондамир, дом№б/н. 
              </p>
              <p>
               Телефон: 91-775-07-00, 94-919-77-00
              </p>
              <p>
               Р/с: 20208000904316956001
              </p>
              <p>
               в АКИБ «Ипотека банк», г.Гулистан
              МФО: 00364; ИНН: 204901642; ОКОНХ:82100.  
              </p>
              <p>
              М.П.                          ___________ 
              </p>
          </div>
          <div style="display:flex;   flex-direction: column; border: 2px solid black; padding: 5px ;" >
              <h4 style="text-align: center;">
                  Заказчик
              </h4>
              <p>
                Адрес:______________________________________
              </p>
              <p>
               тел. (____) _____________________

              </p>
              <p>
              Паспорт ____________________________________

              </p>
              <p>
               в АКИБ «Ипотека банк», г.Гулистан
              выдан  _____________________________________ <br/>
              от ______________
              </p>
              <p>
              М.П.                          ___________ 
              </p>
          </div>
        </div>
    `;

  const [category, setCategory] = useState([])
  const getCategory = () => {
    axios.get('/category-contract', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        setCategory(response.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/login';
        }
      })
  }

  // Физическое лицо
  // const [selectedFile, setSelectedFile] = useState([]);
  const [name, setName] = useState('')
  const [Passport, setPassport] = useState('')
  const [tel, setTel] = useState('')
  const [Sana, setSana] = useState('')
  const [bank, setBank] = useState('')
  const [address, setAddress] = useState('')
  const [inn, setInn] = useState('')
  const [rs, setRs] = useState('')
  const [mfo, setMfo] = useState('')
  const [title, setTitle] = useState('')
  const [target, setTarget] = useState('')
  const [prf, setPrf] = useState('')
  const [price, setPrice] = useState('')
  const [priceText, setPriceText] = useState('')
  const [oked, setOked] = useState('')
  const [content2, setContent2] = useState(initialContent2);
  const idValue = localStorage.getItem('id')
  const [address2, setAddress2] = useState('')
  const [date2, setDate2] = useState('')
  const handleChange = (event) => {
    setTarget(event.target.value);
  };
  const handleChange2 = (event) => {
    setPrf(event.target.value);
  };
  const createContract = (e) => {
    e.preventDefault();
    const newData = {
      name: String(name),
      passport_series: String(Passport),
      phone_number: '+998' + String(tel),
      contract_date: String(Sana),
      info_bank: String(bank),
      info_address: String(address),
      inn: String(inn),
      rs: String(rs),
      mfo: String(mfo),
      category_contract_id: isActive,
      html: String(content),
      title: String(title),
      description: String(target),
      price_info: String(prf),
      price: String(price),
      price_text: String(priceText),
      oked: String(oked),
      create_user_id: Number(idValue),
      address: String(address2),
    };
  
    axios.post('/contract', newData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        const contractId = response.data.contract.id; // Предполагаем, что `id` возвращается в ответе
  
        // После успешного создания контракта загружаем изображения
        await CreateFoto(contractId);
        await CreateDoc(contractId)
        // Вывод сообщения об успехе
        Toastify({
          text: "Добавлено!",
          duration: 3000,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
        }).showToast();
  
        // Сброс состояния формы
        setAddress('');
        setBank('');
        setInn('');
        setMfo('');
        setName('');
        setRs('');
        setTel('');
        setSana('');
        setPassport('');
        setContent(initialContent);
        setTitle('');
        setTarget('');
        setPrf('');
        setPrice('');
        setOked('');
        setAddress2('');
        
        
      })
      .catch((error) => {
        Toastify({
          text: "Ошибка!",
          duration: 3000,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)",
        }).showToast();
  
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/login';
        }
      });
  }
  

  // Физическое лицо

  const createContract2 = (e) => {
    e.preventDefault();
    const newData = {
      name: name,
      passport_series: Passport,
      phone_number: '+998' + tel,
      contract_date: Sana,
      info_bank: bank,
      info_address: address,
      inn: inn,
      rs: rs,
      mfo: mfo,
      category_contract_id: isActive,
      html: content2,
      title: title,
      description: target,
      price_info: prf,
      price: price,
      price_text: priceText,
      oked: oked,
      create_user_id: idValue,
    }




    axios.post('/contract', newData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(async (response) => {
        const contractId = response.data.contract.id; // Предполагаем, что `id` возвращается в ответе
  
        // После успешного создания контракта загружаем изображения
        await CreateFoto(contractId);
        await CreateDoc(contractId)
        Toastify({
          text: "Добавлено!",
          duration: 3000,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
        }).showToast();
        setAddress('')
        setBank('')
        setInn('')
        setMfo('')
        setName('')
        setRs('')
        setTel('')
        setSana('')
        setPassport('')
        setContent2(initialContent2)
        setTitle('')
        setTarget('')
        setPrf('')
        setPrice('')
        setOked('')
      })
      .catch((error) => {
        Toastify({
          text: "Ошибка!",
          duration: 3000,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)",
        }).showToast();

        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/login';
        }
      })
  }




  const createContract3 = (e) => {
    e.preventDefault();
    const newData = {
      name: name,
      passport_series: Passport,
      phone_number: '+998' + tel,
      contract_date: Sana,
      info_bank: bank,
      info_address: address,
      inn: inn,
      rs: rs,
      mfo: mfo,
      category_contract_id: isActive,
      html: content2,
      title: title,
      description: target,
      price_info: prf,
      price: price,
      price_text: priceText,
      oked: oked,
      create_user_id: idValue,
      date: date2
    }

    axios.post('/contract', newData, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then( async (response) => {
        const contractId = response.data.contract.id;
        await CreateFoto(contractId);
        await CreateDoc(contractId)
        Toastify({
          text: "Добавлено!",
          duration: 3000,
          gravity: "top", // `top` or `bottom`
          position: "right", // `left`, `center` or `right`
          backgroundColor: "linear-gradient(to right, #00b09b, #96c93d)",
        }).showToast();
        setAddress('')
        setBank('')
        setInn('')
        setMfo('')
        setName('')
        setRs('')
        setTel('')
        setSana('')
        setPassport('')
        setTitle('')
        setTarget('')
        setPrf('')
        setPrice('')
        setOked('')
        setDate2('')
      })
      .catch((error) => {
        Toastify({
          text: "Ошибка!",
          duration: 3000,
          gravity: "top",
          position: "right",
          backgroundColor: "linear-gradient(to right, #ff5f6d, #ffc371)",
        }).showToast();
        console.log(error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/login';
        }
      })
  }










  const handleModelChange = (newContent) => {
    setContent(newContent);
  };
  const handleModelChange2 = (newContent2) => {
    setContent2(newContent2);
  };
  useEffect(() => {
    getCategory()
  }, [])




  const [content, setContent] = useState(initialContent);

  useEffect(() => {
    setContent(`<div>
           <h2 style="text-align: center;">
            Д О Г О В О Р    №/"«_____» "}
        </h2>
        <p  style="text-align: center; padding-bottom:30px;" >на оценку объекта оценки</p>
        <div style="display:flex;  align-items: center;  justify-content: space-between;">
          <p>  г.Гулистан     </p>
          <p>«${Sana ? `${Sana.split('T')[0]}» ` : "«_____» "}</p>
        </div>
         <h3 style="text-align: center;">
            1.   ДОГОВАРИВАЮЩИЕСЯ  СТОРОНЫ 
        </h3>
        <p style="margin-bottom:50px">
        ООО «SMART CONSULT» именуемый в дальнейшем Исполнитель, в лице директора Назарова А.А. действующего на основании Устава и Лицензии (серии BL 001 реестровый RR-0065, выданной Госкомконкуренцией РУз от 28.11.2016г. страховой полис №Р7/3-7/0008 от 26.01.2024 года выданный АО «ALSKOM» СК) с одной стороны и <span>${name ? `«${name}»` : "«_______»"}</span> именуемый в дальнейшем Заказчик, заключили настоящий договор о нижеследующем:
        </p>
        <h3 style="text-align: center;">
            2.    ПРЕДМЕТ ДОГОВОРА
        </h3>
        <p style="margin-bottom:50px">
          2.1.По возмездному договору на оценку объекта оценки, Исполнитель обязуется по поручению Заказчика, осуществить консультирование Заказчика по определению стоимости (оценки) имущества (объекта оценки) указанных в п.2.2. настоящего договора, а Заказчик обязуется оплатить оценку или производственные работы (услуги).
          2.2.Наименование объекта оценки: <span>${title ? `«${title}»` : "«_______»"}</span>
          2.3.Расположенного по адресу: <span>${address ? `«${address}»` : "«_______»"}</span>
          2.4.Цель оценки:<span>${target ? `«${target}»` : "«_______»"}</span>    
          2.5.Вид определяемой стоимости: <span>${prf ? `«${prf}»` : "«_______»"}</span> 
          2.6.В случае необходимости дополнительных проработок по комплексу оценочных работ - предмету Договора, внесения изменений и уточнения по инициативе Заказчика или иным объективным причинам, включая изменения и установления дополнительных регламентирующих условий, обуславливаемых нормативными актами, требованиями органов государственного надзора или иных компетентных юридических и физических лиц, составляется Дополнительное соглашение к данному Договору или новый Договор, учитывающие изменение трудоемкости.
        </p>
         <h3 style="text-align: center;">
          3.  ЦЕНА ПРЕДМЕТА ДОГОВОРА И ПОРЯДОК РАСЧЕТОВ
        </h3>
        <p style="margin-bottom:50px">
          3.1. Подписывая настоящий договор, Исполнитель и Заказчик удостоверяют, что в результате переговоров определен и согласован размер денежного вознаграждения за проведение оценки или производимые работы (услуги), указанные п.2.1. и реализуемые по заключаемому между Сторонами настоящему договору.
          3.2. Заказчик обязуется оплатить за проведение оценки объекта оценки денежное вознаграждение в размере: (без учета НДС)${price ? `«${price}» сум` : "«_______»"} ${priceText ? `(${priceText}) сум` : "()"}
                                  /сумма прописью/          
          3.3.Размер денежного вознаграждения за проведение оценки объекта оценки уточняется в случае изменения законодательной минимальной заработной платы по соглашению сторон. Индексации подлежит неоплаченная часть стоимости  работ при предоплате на момент введения новой минимальной заработной платы, а индекс удорожания исчисляется, как отношение новой минимальной заработной платы к  минимальной заработной плате в момент заключения договора. 
          3.4.Заказчик – физическое лицо производит 100% предоплаты в течении 3-х банковских дней со дня подписания договора.
          В случае не оплаты в течение указанного срока, договор считается не состоявшимся и Исполнитель вправе отказаться от исполнения обязательств по настоящему договору.
          3.5.Исполнитель сдает Заказчику один экземпляр письменного отчета по оценке объекта оценки и два экземпляра подписанного акта приема-сдачи выполненных работ. 
          3.6.Заказчик обязуется в течение трех календарных дней подписать и вернуть один экземпляр акта приемки-сдачи (для юридического лица) выполненных работ или вернуть Исполнителю отчет по оценке с письменным мотивированным отказом в приеме работ.
        </p>
         <h3 style="text-align: center;">
         4.  СРОКИ ВЫПОЛНЕНИЯ РАБОТ
        </h3>
           <p style="margin-bottom:50px">
         4.1. Датой начала выполнения оценки или работ по настоящему договору считается дата поступления на расчетный счет Исполнителя предоплаты (задатка), в соответствии с п.3.4. настоящего договора, датой окончания работ считается дата подписания акта приемки-сдачи выполненных работ. Время, затрачиваемое на оценку объекта оценки, Исполнитель определяет самостоятельно и имеет право выполнить досрочно.
        </p>
           <h3 style="text-align: center;">
        5.  ПРАВА И ОБЯЗАННОСТИ СТОРОН
        </h3>
        <p style="margin-bottom:50px">
          5.1. Заказчик обязан:
          5.1.1. Оплатить стоимость оценки объекта оценки указанной в п.3.2. настоящего договора, за услуги Исполнителя и принять у него работу в соответствии с пунктами 3.5. и 3.6.
          5.1.2. Обеспечить предоставление информации и необходимых сведений для проведения оценки, обеспечить соответствующие условия для проведения оценки или работ (услуг)
          5.2. Заказчик имеет право:
          5.2.1. Отказаться от поручаемой оценки объекта оценки по настоящему договору по своей инициативе только в тех случаях, когда Исполнитель не приступил к исполнению настоящего договора в согласованные сроки. 

          В случае начала выполнения работ (услуг) по оценки объекта оценки Исполнителем, работа завершается на основании двухстороннего акта на выполненную часть работы
          5.3. Исполнитель обязан: 
          5.3.1. Выполнять оценку объекта оценки в соответствии со стандартами и нормативно-правовыми актами, регламентирующими оценочную деятельность в Республики Узбекистан.
          5.3.2. Сообщить Заказчику о невозможности своего участия в проведении оценки объекта оценки вследствие возникших обстоятельств, препятствующих проведению объективной оценки.
          5.4. Исполнитель имеет право:
          5.4.1.Привлекать по своему усмотрению, за свой счет, самостоятельные экспертные группы для выполнения оценки объекта оценки по настоящему договору.
          5.4.2.Требовать от заказчика: обеспечения доступа к документации; получать разъяснения и дополнительные сведения, необходимые для осуществления оценки объекта оценки, обеспечение соответствующих условий для проведения  оценки объекта оценки.
          5.4.3. Выбирать самостоятельно методы оценки.
          5.4.4.Отказаться от проведения оценки объекта оценки в случаях: если Заказчик нарушил условия договора, не обеспечил предоставление необходимой информации либо не обеспечил, соответствующие условия для работы.
        </p>
          <h3 style="text-align: center;">
             6.  ОТВЕТСТВЕННОСТЬ СТОРОН
        </h3>
        <p style="margin-bottom:50px">
        6.1. За нарушение сроков выполнения работ Исполнитель уплачивает Заказчику пени в размере 0,5% от стоимости работ за каждый день просрочки, но не более 50% от общей суммы настоящего договора.
        6.2. При не своевременной оплате, Заказчик уплачивает Исполнителю пени в размере 0,5% от суммы просроченного платежа за каждый день просрочки, но не более 50% от общей суммы настоящего договора.
        6.3. Уплата пени не освобождает сторону, нарушившую договорные обязательства от их дальнейшего исполнения и возмещения убытков.
        6.4.Исполнитель не несет ответственность за предоставленную Заказчиком информацию, которая необходима для выполнения  оценки или работ (услуг).
        6.5.Исполнитель не несет ответственность за не выполнение оценки или работ (услуг) по срокам, в случаи не предоставления Заказчиком исходных данных.
        6.6.Вся информация, полученная в ходе выполнения настоящего договора, считается строго конфиденциальной и не подлежит разглашению или передаче третьем лицам, за исключением случаев предусмотренных действующим законодательством.
        </p>
            <h3 style="text-align: center;">
              7. ФОРС-МАЖОР
        </h3>
        <p style="margin-bottom:50px">
        7.1.Стороны не несут ответственность за не выполнение или не полное выполнение ими своих обязательств, в случае наступления обстоятельств непреодолимой силы, вызванных прямо или косвенно землетрясением, наводнением, пожаром другими стихийными бедствиями, гражданских волнений, вмешательство правительства, оказывающих влияние на выполнение обязательств сторонами по настоящему договору, сроки выполнения этих обязательств соразмерно отодвигаются  на время действия этих обстоятельств.
        </p>
           <h3 style="text-align: center;">
              8.   ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ
        </h3>
        <p style="margin-bottom:50px">
          8.1.В случае возникновения споров между Сторонами по вопросам исполнения настоящего договора, Стороны примут все меры к их разрешению путем переговоров. Споры и разногласия, по которым Стороны не достигли договоренности, подлежат рассмотрению в  установленном законодательном порядке.
          8.2.Настоящий договор может быть изменен или прекращен до полного выполнения Сторонами принятых обязательств, только по взаимному соглашению Сторон. Все изменения или дополнения оформляются дополнительными соглашениями в письменной форме к настоящему договору, подписываются Сторонами и составляют его неотъемлемую часть. 
        </p>
             <h3 style="text-align: center;">
              9.    Заключительные положения.
        </h3>
        <p style="margin-bottom:50px">
        9.1.Отношения возникшие при заключении, исполнении, изменении, расторжении настоящего договора и неоговоренные в нем, регулируются Гражданским  кодексом Р.Уз., Законом Р.Уз."О договорно-правовой базе деятельности хозяйствующих субъектов" и Законом Р.Уз."Об оценочной деятельности".
        9.2.Настоящий договор составлен в двух экземплярах. Оба экземпляра идентичны и имеют одинаковую силу. Один экземпляр находится у Исполнителя, а другой - у Заказчика.
        </p>
             <h3 style="text-align: center;">
              10.   Адреса и банковские реквизиты сторон.
        </h3>
        <div >
          <div style="display:flex;   flex-direction: column; border: 2px solid black; padding: 5px ;" >
              <h4 style="text-align: center;">
                  Исполнитель:
              </h4>
              <h4 style="text-align: center;">
                  ООО «SMART CONSULT»
              </h4>
              <p>
                Адрес:Сырдарьинская область, г.Гулистан, ул.Хондамир, дом№б/н. 
              </p>
              <p>
               Телефон: 91-775-07-00, 94-919-77-00
              </p>
              <p>
               Р/с: 20208000904316956001
              </p>
              <p>
               в АКИБ «Ипотека банк», г.Гулистан
              МФО: 00364; ИНН: 204901642; ОКОНХ:82100.  
              </p>
              <p>
              М.П.                          ___________ 
              </p>
          </div>
          <div style="display:flex;   flex-direction: column; border: 2px solid black; padding: 5px ;" >
              <h4 style="text-align: center;">
                  Заказчик:${name ? `«${name}»` : "«_______»"}
              </h4>
              <p>
                Адрес:${address ? `«${address}»` : "«_______»"}
              </p>
              <p>
               тел:${tel ? `«${tel}»` : "«_______»"}
              </p>
              <p>
              Паспорт: ${Passport ? `«${Passport}»` : "«_______»"}
              </p>
       
              <p>
              М.П.                          ___________ 
              </p>
          </div>
        </div>
    `);
    setContent2(`
        <div>
        <h2 style="text-align: center;">
            Д О Г О В О Р    №/"«_____»
        </h2>
        <p style="text-align: center; padding-bottom:30px;">   на оценку объекта оценки</p>
        <div style="display:flex;  align-items: center;  justify-content: space-between;">
          <p>  г.Гулистан     </p>
        <p>«${Sana ? `${Sana.split('T')[0]}» ` : "«_____» "}</p>
        </div>
         <h3 style="text-align: center;">
            1.   ДОГОВАРИВАЮЩИЕСЯ  СТОРОНЫ 
        </h3>
        <p style="margin-bottom:50px">
      ООО «SMART CONSULT» именуемый в дальнейшем Исполнитель, в лице директора Назарова А.А. действующего на основании Устава и Лицензии (серии BL 001 реестровый RR-0065, выданной Госкомконкуренцией РУз от 28.11.2016г. страховой полис №Р7/3-7/0008 от 26.01.2024 года выданный АО «ALSKOM» СК) с одной стороны и ООО <span>${name ? `«${name}»` : "«_______»"}</span> именуемый в дальнейшем Заказчик, в лице Каримов Ш.З. действующего на основании Устава с другой стороны заключили настоящий договор о нижеследующем:

        </p>
        <h3 style="text-align: center;">
            2.    ПРЕДМЕТ ДОГОВОРА
        </h3>
        <p style="margin-bottom:50px">
           2.1.По возмездному договору на оценку объекта оценки, Исполнитель обязуется по поручению Заказчика, осуществить консультирование Заказчика по определению стоимости (оценки) имущества (объекта оценки) указанных в п.2.2. настоящего договора, а Заказчик обязуется оплатить оценку или производственные работы (услуги).
          2.2.Наименование объекта оценки: <span>${title ? `«${title}»` : "«_______»"}</span>
          2.3.Расположенного по адресу:  <span>${address ? `«${address}»` : "«_______»"}</span>
          2.4.Цель оценки:${target ? `«${target}»` : "«_______»"}
          2.5.Вид определяемой стоимости: ${prf ? `«${prf}»` : "«_______»"}
          2.6.В случае необходимости дополнительных проработок по комплексу оценочных работ - предмету Договора, внесения изменений и уточнения по инициативе Заказчика или иным объективным причинам, включая изменения и установления дополнительных регламентирующих условий, обуславливаемых нормативными актами, требованиями органов государственного надзора или иных компетентных юридических и физических лиц, составляется Дополнительное соглашение к данному Договору или новый Договор, учитывающие изменение трудоемкости.

        </p>
         <h3 style="text-align: center;">
          3.  ЦЕНА ПРЕДМЕТА ДОГОВОРА И ПОРЯДОК РАСЧЕТОВ
        </h3>
        <p style="margin-bottom:50px">
          3.1. Подписывая настоящий договор, Исполнитель и Заказчик удостоверяют, что в результате переговоров определен и согласован размер денежного вознаграждения за проведение оценки или производимые работы (услуги), указанные п.2.1. и реализуемые по заключаемому между Сторонами настоящему договору.
          3.2. Заказчик обязуется оплатить за проведение оценки объекта оценки денежное вознаграждение в размере: ${price ? `«${price}» сум` : "«_______»"} ${priceText ? `(${priceText}) сум` : "()"} сум 
          3.3.Размер денежного вознаграждения за проведение оценки объекта оценки уточняется в случае изменения законодательной минимальной заработной платы по соглашению сторон. Индексации подлежит неоплаченная часть стоимости работ при предоплате на момент введения новой минимальной заработной платы, а индекс удорожания исчисляется, как отношение новой минимальной заработной платы к минимальной заработной плате в момент заключения договора. 
          3.4.Заказчик – юридическое лицо производит 100% предоплаты в течении 3-х банковских дней со дня подписания договора.
          В случае не оплаты в течение указанного срока, договор считается не состоявшимся и Исполнитель вправе отказаться от исполнения обязательств по настоящему договору.
          3.5.Исполнитель сдает Заказчику один экземпляр письменного отчета по оценке объекта оценки и два экземпляра подписанного акта приема-сдачи выполненных работ. 
          3.6.Заказчик обязуется в течение трех календарных дней подписать и вернуть один экземпляр акта приемки-сдачи (для юридического лица) выполненных работ или вернуть Исполнителю отчет по оценке с письменным мотивированным отказом в приеме работ.

        </p>
         <h3 style="text-align: center;">
         4.  СРОКИ ВЫПОЛНЕНИЯ РАБОТ
        </h3>
           <p style="margin-bottom:50px">
        4.1. Датой начала выполнения оценки или работ по настоящему договору считается дата поступления на расчетный счет Исполнителя предоплаты (задатка), в соответствии с п.3.4. настоящего договора, датой окончания работ считается дата подписания акта приемки-сдачи выполненных работ. Время, затрачиваемое на оценку объекта оценки, Исполнитель определяет самостоятельно и имеет право выполнить досрочно.

        </p>
           <h3 style="text-align: center;">
        5.  ПРАВА И ОБЯЗАННОСТИ СТОРОН
        </h3>
        <p style="margin-bottom:50px">
         5.1. Заказчик обязан:
        5.1.1. Оплатить стоимость оценки объекта оценки указанной в п.3.2. настоящего договора, за услуги Исполнителя и принять у него работу в соответствии с пунктами 3.5. и 3.6.
        5.1.2. Обеспечить предоставление информации и необходимых сведений для проведения оценки, обеспечить соответствующие условия для проведения оценки или работ (услуг)
        5.2. Заказчик имеет право:
        5.2.1. Отказаться от поручаемой оценки объекта оценки по настоящему договору по своей инициативе только в тех случаях, когда Исполнитель не приступил к исполнению настоящего договора в согласованные сроки. 
        В случае начала выполнения работ (услуг) по оценки объекта оценки Исполнителем, работа завершается на основании двухстороннего акта на выполненную часть работы
        5.3. Исполнитель обязан: 
        5.3.1. Выполнять оценку объекта оценки в соответствии со стандартами и нормативно-правовыми актами, регламентирующими оценочную деятельность в Республики Узбекистан.
        5.3.2. Сообщить Заказчику о невозможности своего участия в проведении оценки объекта оценки вследствие возникших обстоятельств, препятствующих проведению объективной оценки.
        5.4. Исполнитель имеет право:
        5.4.1.Привлекать по своему усмотрению, за свой счет, самостоятельные экспертные группы для выполнения оценки объекта оценки по настоящему договору.
        5.4.2.Требовать от заказчика: обеспечения доступа к документации; получать разъяснения и дополнительные сведения, необходимые для осуществления оценки объекта оценки, обеспечение соответствующих условий для проведения  оценки объекта оценки.
        5.4.3. Выбирать самостоятельно методы оценки.
        5.4.4.Отказаться от проведения оценки объекта оценки в случаях: если Заказчик нарушил условия договора, не обеспечил предоставление необходимой информации либо не обеспечил, соответствующие условия для работы.

        </p>
          <h3 style="text-align: center;">
             6.  ОТВЕТСТВЕННОСТЬ СТОРОН
        </h3>
        <p style="margin-bottom:50px">
        6.1. За нарушение сроков выполнения работ Исполнитель уплачивает Заказчику пени в размере 0,5% от стоимости работ за каждый день просрочки, но не более 50% от общей суммы настоящего договора.
6.2. При не своевременной оплате, Заказчик уплачивает Исполнителю пени в размере 0,4% от суммы просроченного платежа за каждый день просрочки, но не более 50% от общей суммы настоящего договора.
6.3. Уплата пени не освобождает сторону, нарушившую договорные обязательства от их дальнейшего исполнения и возмещения убытков.
6.4.Исполнитель не несет ответственность за предоставленную Заказчиком информацию, которая необходима для выполнения  оценки или работ (услуг).
6.5.Исполнитель не несет ответственность за не выполнение оценки или работ (услуг) по срокам, в случаи не предоставления Заказчиком исходных данных.
6.6.Вся информация, полученная в ходе выполнения настоящего договора, считается строго конфиденциальной и не подлежит разглашению или передаче третьем лицам, за исключением случаев предусмотренных действующим законодательством.

        </p>
            <h3 style="text-align: center;">
              7. ФОРС-МАЖОР
        </h3>
        <p style="margin-bottom:50px">
       7.1.Стороны не несут ответственность за не выполнение или не полное выполнение ими своих обязательств, в случае наступления обстоятельств непреодолимой силы, вызванных прямо или косвенно землетрясением, наводнением, пожаром другими стихийными бедствиями, гражданских волнений, вмешательство правительства, оказывающих влияние на выполнение обязательств сторонами по настоящему договору, сроки выполнения этих обязательств соразмерно отодвигаются  на время действия этих обстоятельств.

        </p>
           <h3 style="text-align: center;">
              8.   ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ
        </h3>
        <p style="margin-bottom:50px">
        8.1.В случае возникновения споров между Сторонами по вопросам исполнения настоящего договора, Стороны примут все меры к их разрешению путем переговоров. Споры и разногласия, по которым Стороны не достигли договоренности, подлежат рассмотрению в установленном законодательном порядке.
8.2.Настоящий договор может быть изменен или прекращен до полного выполнения Сторонами принятых обязательств, только по взаимному соглашению Сторон. Все изменения или дополнения оформляются дополнительными соглашениями в письменной форме к настоящему договору, подписываются Сторонами и составляют его неотъемлемую часть. 

        </p>
             <h3 style="text-align: center;">
              9.    Заключительные положения.
        </h3>
        <p style="margin-bottom:50px">
       9.1.Отношения возникшие при заключении, исполнении, изменении, расторжении настоящего договора и неоговоренные в нем, регулируются Гражданским  кодексом Р.Уз., Законом Р.Уз."О договорно-правовой базе деятельности хозяйствующих субъектов" и Законом Р.Уз."Об оценочной деятельности".
9.2.Настоящий договор составлен в двух экземплярах. Оба экземпляра идентичны и имеют одинаковую силу. Один экземпляр находится у Исполнителя, а другой - у Заказчика. 

        </p>
             <h3 style="text-align: center;">
              10.   Адреса и банковские реквизиты сторон.
        </h3>
        <div  ">
          <div style="display:flex;   flex-direction: column; border: 2px solid black; padding: 5px ;" >
              <h4 style="text-align: center;">
                  Исполнитель:
              </h4>
              <h4 style="text-align: center;">
                  ООО «SMART CONSULT»
              </h4>
              <p>
                Адрес:Сырдарьинская область, г.Гулистан, ул.Хондамир, дом№б/н. 
              </p>
              <p>
               Телефон: 91-775-07-00, 94-919-77-00
              </p>
              <p>
               Р/с: 20208000904316956001
              </p>
              <p>
               в АКИБ «Ипотека банк», г.Гулистан
              МФО: 00364; ИНН: 204901642; ОКОНХ:82100.  
              </p>
              <p>
              М.П.                          ___________ 
              </p>
          </div>
          <div style="display:flex;   flex-direction: column; border: 2px solid black; padding: 5px ;" >
              <h4 style="text-align: center;">
                 Заказчик
              </h4>
              <h4 style="text-align: center;">
                  ООО ${name ? `«${name}»` : "«_______»"}
              </h4>
              <p>
             Адрес:${address ? `«${address}»` : "«_______»"}
              </p>
              <p>
                тел:${tel ? `«${tel}»` : "«_______»"}
              </p>
              <p>
              Банк:${bank ? `«${bank}»` : "«_______»"}
              </p>
              <p>
              МФО:  ${mfo ? `«${mfo}»` : "«_______»"} <br> ИНН:${mfo ? `«${mfo}»` : "«_______»"} <br> ОКЭД:${oked ? `«${oked}»` : "«_______»"}

              </p>
              <p>
             М.П.    ______________ 
              </p>
          </div>
        </div>
        </div>
        </div>
     `)
  }, [name, address, tel, Passport, bank, Sana, mfo, title, target, prf, price, oked, priceText,]);

  const [files, setFiles] = useState([]);

// Функция для обработки изменений в input (выбор файлов)
const postFoto = (e) => {
  setFiles(prevFiles => [...prevFiles, ...Array.from(e.target.files)]);
};

// Функция для удаления файла из списка
const removeFile = (index) => {
  setFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
};

const uploadFile = (file, contractId) => {
  const formData = new FormData();
  formData.append('image', file);
  formData.append('contract_id', contractId); // Передаем ID контракта
  
  return axios.post('/image', formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Функция для обработки отправки формы
const CreateFoto = async (contractId) => {
  try {
    for (const file of files) {
      await uploadFile(file, contractId);
    }
    setFiles([]); // Очищаем state после успешной загрузки
  } catch (error) {
    console.log('Ошибка при загрузке:', error);
  }
};

const [filesDoc, setFilesdoc] = useState([]);

const PostFile = (e) => {
  setFilesdoc(prevFiles => [...prevFiles, ...Array.from(e.target.files)]);
};

const uploadFileDoc = (doc, contractId) => {
  const formData = new FormData();
  formData.append('document', doc);
  formData.append('contract_id', contractId);
  return axios.post('/document', formData, {
    headers:{
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const CreateDoc = async (contractId) => { 
  try {
    for (const doc of filesDoc) {
      await uploadFileDoc(doc, contractId);
    }
    setFilesdoc([]); // Очищаем state после успешной загрузки
  } catch (error) {
    console.log('Ошибка при загрузке:', error);
    console.log(filesDoc);
  }
};

  return (
    <div className='CreateContracts'>
      <Header />
      <div className='CreateContracts-content'>
        <div className='CreateContracts2'>
          <div className='CreateContract-saidbar'>
            {category.map((item,) => (
              <button key={item} className={isActive === item.id ? 'ConActive' : ''}
                onClick={() => activeCon(item.id)}
              >
                {item.name}
              </button>
            ))}
          </div>
          <form className={`${isActive === 1 ? "yozperson-active" : "dn"}`} onSubmit={createContract}>
            <h2>Создать Контракт для Физических лиц</h2>
            <label htmlFor="name">
              <h3>Ф.И.О (Заказчик)</h3>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                id='name' type="text" />
            </label>
            <label htmlFor="title">
              <h3>Наименование обекта</h3>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                id='title' type="text" />
            </label>
            <label htmlFor="adres">
              <h3>Информация (Адрес)</h3>
              <textarea
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                name="" id="adres"></textarea>
            </label>
            <label htmlFor="adres2">
              <h3>Адрес заказчика</h3>
              <textarea
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                name="" id="adres2"></textarea>
            </label>
            <label htmlFor="pinfo">
              <h3>Цель оценки</h3>
              <select value={target} onChange={handleChange} name="" id="">
                <option value="1" defaultValue></option>

                <option value=" Для передачи объекта в качестве залога ">
                  Для передачи объекта в качестве залога
                </option>
                <option value=" Для передачи объекта в уставный фонд ">
                  Для передачи объекта в уставный фонд
                </option>
                <option value="Для реализаций объекта">
                  Для реализаций объекта
                </option>
                <option value="Для передачи объекта в баланс предприятие">
                  Для передачи объекта в баланс предприятие
                </option>
                <option value="Для консультирование">
                  Для консультирование
                </option>
              </select>
            </label>
            <label htmlFor="ppinfo">
              <h3>Вид определяемой стоимости</h3>
              <select value={prf} onChange={handleChange2} name="" id="">
                <option value="1" defaultValue></option>
                <option value="Рыночная стоимость" >Рыночная стоимость</option>
                <option value="Инвестиционная стоимость" >Инвестиционная стоимость</option>
                <option value="Стоимость действующего предприятие" >Стоимость действующего предприятие</option>
                <option value="Страховая стоимость" >Страховая стоимость</option>
                <option value="Стоимость для целей налогооблажения" >Стоимость для целей налогооблажения</option>
                <option value="Стоимость для целей налогооблажения" >Остаточная стоимость замецения</option>
                <option value="Утилизационная стоимость" >Утилизационная стоимость</option>
                <option value="Ликвидационная стоимость" >Ликвидационная стоимость</option>
                <option value="стоимость объединения" >стоимость объединения</option>
                <option value="Специальный покупатель" >Специальный покупатель</option>
                <option value="Специальная стоимость" >Специальная стоимость</option>
                <option value="Справедливая стоимость" >Справедливая стоимость</option>
              </select>
            </label>
            <label htmlFor="price">
              <h3>Денежное вознаграждение</h3>
              <input
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                id='price' type="number" />
            </label>
            <label htmlFor="ppinfo">
              <h3>Денежное вознаграждение (словах)</h3>
              <textarea
                value={priceText}
                onChange={(e) => setPriceText(e.target.value)}
                name="" id="ppinfo"></textarea>
            </label>
            <label htmlFor="pasport">
              <h3>Серия паспорта</h3>
              <input
                value={Passport}
                onChange={(e) => setPassport(e.target.value)}
                id='pasport' type="text" />
            </label>
            <label htmlFor="tel">
              <h3>Телефон номера</h3>
              <input
                placeholder="+998"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                id='tel' type="number" />
            </label>
            <label htmlFor="data">
              <h3>Дата контракта</h3>
              <input

                placeholder="dd-mm-yyyy"
                value={Sana}
                onChange={(e) => setSana(e.target.value)}
                type="datetime-local"
                id="meeting-time"
                name="begin"
              />
            </label>


            {/* <div className="modal-foto">
              <h3>Фото</h3>
              <label className="file-input-container" htmlFor="photo">
                <span className='soz'>Фото</span>
                <input
                  onChange={handleFileChange1}
                  id="photo" accept="image/*" type="file" />
              </label>
            </div> */}
            {/* <div className="modal-foto">
              <h3>Файл</h3>
              <label className="file-input-container" htmlFor="photo">
                <span className='soz'>Файл</span>
                <input
                  onChange={handleFileChange2}
                  id="photo" accept="image/*" type="file" />
              </label>
            </div> */}
            <button type='submit'>Создать</button>
          </form>
          <form className={`${isActive === 2 ? "yozperson-active" : "dn"}`} onSubmit={createContract2}>
            <h2>Создать Контракт для Юридических лиц </h2>

            <label htmlFor="name">
              <h3>Заказчик</h3>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                id='name' type="text" />
            </label>
            <label htmlFor="title">
              <h3>Наименование обекта</h3>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                id='title' type="text" />
            </label>
            <label htmlFor="bank">
              <h3>Информация (адрес)</h3>
              <textarea
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                name="" id="bank"></textarea>
            </label>
            <label htmlFor="adres2">
              <h3>Адрес заказчика</h3>
              <textarea
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                name="" id="adres2"></textarea>
            </label>
            <label htmlFor="pinfo">
              <h3>Цель оценки</h3>
              <select value={target} onChange={handleChange} name="" id="">
                <option value="1" defaultValue></option>

                <option value=" Для передачи объекта в качестве залога ">
                  Для передачи объекта в качестве залога
                </option>
                <option value=" Для передачи объекта в уставный фонд ">
                  Для передачи объекта в уставный фонд
                </option>
                <option value="Для реализаций объекта">
                  Для реализаций объекта
                </option>
                <option value="Для передачи объекта в баланс предприятие">
                  Для передачи объекта в баланс предприятие
                </option>
                <option value="Для консультирование">
                  Для консультирование
                </option>
              </select>
            </label>
            <label htmlFor="ppinfo">
              <h3>Вид определяемой стоимости</h3>
              <select value={prf} onChange={handleChange2} name="" id="">
                <option value="1" defaultValue></option>
                <option value="Рыночная стоимость" >Рыночная стоимость</option>
                <option value="Инвестиционная стоимость" >Инвестиционная стоимость</option>
                <option value="Стоимость действующего предприятие" >Стоимость действующего предприятие</option>
                <option value="Страховая стоимость" >Страховая стоимость</option>
                <option value="Стоимость для целей налогооблажения" >Стоимость для целей налогооблажения</option>
                <option value="Стоимость для целей налогооблажения" >Остаточная стоимость замецения</option>
                <option value="Утилизационная стоимость" >Утилизационная стоимость</option>
                <option value="Ликвидационная стоимость" >Ликвидационная стоимость</option>
                <option value="стоимость объединения" >стоимость объединения</option>
                <option value="Специальный покупатель" >Специальный покупатель</option>
                <option value="Специальная стоимость" >Специальная стоимость</option>
                <option value="Справедливая стоимость" >Справедливая стоимость</option>
              </select>
            </label>
            <label htmlFor="price">
              <h3>Денежное вознаграждение</h3>
              <input
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                id='price' type="number" />
            </label>
            <label htmlFor="ppinfo">
              <h3>Денежное вознаграждение (словах)</h3>
              <textarea
                value={priceText}
                onChange={(e) => setPriceText(e.target.value)}
                name="" id="ppinfo"></textarea>
            </label>
            <label htmlFor="pasport">
              <h3>Серия паспорта</h3>
              <input
                value={Passport}
                onChange={(e) => setPassport(e.target.value)}
                id='pasport' type="text" />
            </label>
            <label htmlFor="inn">
              <h3>ИНН</h3>
              <input
                value={inn}
                onChange={(e) => setInn(e.target.value)}
                id='inn' type="number" />
            </label>
            <label htmlFor="tel">
              <h3>Телефон номера</h3>
              <input
                placeholder="+998"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                id='tel' type="number" />
            </label>
            <label htmlFor="rs">
              <h3>Р/с</h3>
              <input
                value={rs}
                onChange={(e) => setRs(e.target.value)}
                id='rs' type="number" />
            </label>
            <label htmlFor="mfo">
              <h3>МФО</h3>
              <input
                value={mfo}
                onChange={(e) => setMfo(e.target.value)}
                id='mfo' type="number" />
            </label>
            <label htmlFor="data">
              <h3>Дата контракта</h3>
              <input
                value={Sana}
                onChange={(e) => setSana(e.target.value)}
                type="datetime-local"
                id="meeting-time"
                name="meeting-time"
              />
            </label>
            <label htmlFor="oked">
              <h3>ОКЭД</h3>
              <input
                value={oked}
                onChange={(e) => setOked(e.target.value)}
                id='oked' type="number" />
            </label>
            <label htmlFor="info">
              <h3>Информация (банк)</h3>
              <textarea
                value={bank}
                onChange={(e) => setBank(e.target.value)}
                name="" id="info"></textarea>
            </label>
              <button type='submit'>Создать</button>
          </form>
          <form className={`${isActive === 3 ? "yozperson-active" : "dn"}`} onSubmit={createContract3}>
            <h2>Аукцион тендер</h2>

            <label htmlFor="name">
              <h3>Заказчик</h3>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                id='name' type="text" />
            </label>
            <label htmlFor="title">
              <h3>Наименование обекта</h3>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                id='title' type="text" />
            </label>
            <label htmlFor="bank">
              <h3>Информация (адрес)</h3>
              <textarea
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                name="" id="bank"></textarea>
            </label>
            <label htmlFor="adres2">
              <h3>Адрес заказчика</h3>
              <textarea
                value={address2}
                onChange={(e) => setAddress2(e.target.value)}
                name="" id="adres2"></textarea>
            </label>
            <label htmlFor="pinfo">
              <h3>Цель оценки</h3>
              <select value={target} onChange={handleChange} name="" id="">
                <option value="1" defaultValue></option>

                <option value=" Для передачи объекта в качестве залога ">
                  Для передачи объекта в качестве залога
                </option>
                <option value=" Для передачи объекта в уставный фонд ">
                  Для передачи объекта в уставный фонд
                </option>
                <option value="Для реализаций объекта">
                  Для реализаций объекта
                </option>
                <option value="Для передачи объекта в баланс предприятие">
                  Для передачи объекта в баланс предприятие
                </option>
                <option value="Для консультирование">
                  Для консультирование
                </option>
              </select>
            </label>
            <label htmlFor="ppinfo">
              <h3>Вид определяемой стоимости</h3>
              <select value={prf} onChange={handleChange2} name="" id="">
                <option value="1" defaultValue></option>
                <option value="Рыночная стоимость" >Рыночная стоимость</option>
                <option value="Инвестиционная стоимость" >Инвестиционная стоимость</option>
                <option value="Стоимость действующего предприятие" >Стоимость действующего предприятие</option>
                <option value="Страховая стоимость" >Страховая стоимость</option>
                <option value="Стоимость для целей налогооблажения" >Стоимость для целей налогооблажения</option>
                <option value="Стоимость для целей налогооблажения" >Остаточная стоимость замецения</option>
                <option value="Утилизационная стоимость" >Утилизационная стоимость</option>
                <option value="Ликвидационная стоимость" >Ликвидационная стоимость</option>
                <option value="стоимость объединения" >стоимость объединения</option>
                <option value="Специальный покупатель" >Специальный покупатель</option>
                <option value="Специальная стоимость" >Специальная стоимость</option>
                <option value="Справедливая стоимость" >Справедливая стоимость</option>
              </select>
            </label>
            <label htmlFor="price">
              <h3>Денежное вознаграждение</h3>
              <input
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                id='price' type="number" />
            </label>
            <label htmlFor="ppinfo">
              <h3>Денежное вознаграждение (словах)</h3>
              <textarea
                value={priceText}
                onChange={(e) => setPriceText(e.target.value)}
                name="" id="ppinfo"></textarea>
            </label>
            <label htmlFor="pasport">
              <h3>Серия паспорта</h3>
              <input
                value={Passport}
                onChange={(e) => setPassport(e.target.value)}
                id='pasport' type="text" />
            </label>
            <label htmlFor="inn">
              <h3>ИНН</h3>
              <input
                value={inn}
                onChange={(e) => setInn(e.target.value)}
                id='inn' type="number" />
            </label>
            <label htmlFor="tel">
              <h3>Телефон номера</h3>
              <input
                placeholder="+998"
                value={tel}
                onChange={(e) => setTel(e.target.value)}
                id='tel' type="number" />
            </label>
            <label htmlFor="rs">
              <h3>Р/с</h3>
              <input
                value={rs}
                onChange={(e) => setRs(e.target.value)}
                id='rs' type="number" />
            </label>
            <label htmlFor="mfo">
              <h3>МФО</h3>
              <input
                value={mfo}
                onChange={(e) => setMfo(e.target.value)}
                id='mfo' type="number" />
            </label>
            <label htmlFor="data">
              <h3>Дата контракта</h3>
              <input
                value={Sana}
                onChange={(e) => setSana(e.target.value)}
                type="datetime-local"
                id="meeting-time"
                name="meeting-time"
              />
            </label>
            <label htmlFor="data2">
              <h3>Дата обретения</h3>
              <input
                value={date2}
                onChange={(e) => setDate2(e.target.value)}
                type="datetime-local"
                id="meeting-time2"
                name="meeting-time"
              />
            </label>
            <label htmlFor="oked">
              <h3>ОКЭД</h3>
              <input
                value={oked}
                onChange={(e) => setOked(e.target.value)}
                id='oked' type="number" />
            </label>
            <label htmlFor="info">
              <h3>Информация (банк)</h3>
              <textarea
                value={bank}
                onChange={(e) => setBank(e.target.value)}
                name="" id="info"></textarea>
            </label>
            <button type='submit'>Создать</button>
          </form>
            <div className='CreateContract__foto'>
            <div className="modal-foto">
                <h3>Фото</h3>
                <label className="file-input-container" htmlFor="photo">
                  <span className='soz'>Фото</span>
                  <input
                    onChange={postFoto}
                    multiple 
                    id="photo" accept="image/*" type="file" />
                </label>
              </div>
              <div className='CreateContract__foto__number'>
                  <h2>
                    Загружено фото :
                  </h2>
                  <span>
                  {files.length}
                  </span>
                </div>
                <div className='CreateContract__foto__preview'>
            {files.length > 0 && (
              <div>
                {files.map((file, index) => (
                  <div key={index} style={{ display: 'inline-block', margin: '5px' }}>
                    <img
                      src={URL.createObjectURL(file)} // Создать временный URL для изображения
                      alt={`Предварительный просмотр ${index}`}
                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    />
                    <button onClick={() => removeFile(index)} style={{ display: 'block', marginTop: '5px' }}>
                      Удалить
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
            </div>
            <div className='CreateContract__foto'>
            <div className="modal-foto">
                <h3>Файл</h3>
                <label className="file-input-container" htmlFor="photo">
                  <span className='soz'>Файл</span>
                  <input
                    onChange={PostFile}
                    multiple 
                    id="photo" type="file" />
                </label>
              </div>
              <div className='CreateContract__foto__number'>
                  <h2>
                    Загружено файл :
                  </h2>
                  <span>
                  {filesDoc.length}
                  </span>
                </div>
            </div>
        </div>
        <div className='CreateContracts-text'>
          <div className={`fizperson ${isActive === 2 ? "fizperson-active" : "dn"}`}>
            <FroalaEditorComponent
              onSubmit={createContract2}
              tag='textarea'
              model={content2}
              onModelChange={handleModelChange2}
              config={{
                toolbarButtons: ['align', 'bold', 'italic', 'underline', 'paragraphFormat', 'alert'],
                pluginsEnabled: ['align']
              }}
            />
          </div>
          <div className={`yozperson ${isActive === 1 ? "yozperson-active" : "dn"}`}>
            <FroalaEditorComponent
              tag='textarea'
              model={content}
              onModelChange={handleModelChange}
              config={{
                toolbarButtons: ['align', 'bold', 'italic', 'underline', 'paragraphFormat', 'alert'],
                pluginsEnabled: ['align']
              }}
            />
          </div>
          <div className={`person ${isActive === 3 ? "fizperson-active" : "dn"}`}>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateContracts;
